<template>
  <div>
    <a-modal
      width="1100px"
      :title="Title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="cancel"
    >
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <a-form-item
            label="考试名称："
            name="title"
          >
            <a-input
              allow-clear
              placeholder="请输入"
              v-model:value="formState.title"
              style="width:80%"
            />
          </a-form-item>
          <a-form-item
            label="考试描述："
            name="comment"
          >
            <a-textarea
              allow-clear
              placeholder="请输入"
              v-model:value="formState.comment"
              style="width:80%"
            />
          </a-form-item>
          <a-form-item
            label="考试试卷"
            name="examinationPaperId"
          >
            <a-select
              ref="select"
              style="width:80%"
              show-search
              placeholder="请选择"
              v-model:value="formState.examinationPaperId"
              :filter-option="filterOption"
              :options="options"
            >
            </a-select>
          </a-form-item>
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="考试时长（分钟）："
                name="duration"
              >
                <a-time-picker
                  v-model:value="formState.duration"
                  value-format="HH:mm:ss"
                  :default-value="'00:01:00'"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="及格分数："
                name="passMark"
              >
                <a-input
                  allow-clear
                  placeholder="请输入"
                  v-model:value="formState.passMark"
                  style="width:80%"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="考试有效时间："
                name="time"
              >
                <a-range-picker
                  v-model:value="formState.time"
                  value-format="YYYY-MM-DD"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;position:relative;">
            <div style="height:40px;line-height: 40px;background:rgb(225, 225, 225);">
              <h2 style="margin-left:10px">考试人员名单</h2>
            </div>
            <a-button
              @click="add"
              type="primary"
              style="position:relative;top:5px;left:80%"
            >添加人员</a-button>
            <a-button
              @click="del"
              type="primary"
              style="position:relative;top:5px;left:80%"
            >删除</a-button>

            <a-table
              style="margin-top: 10px"
              :columns="columns"
              :data-source="sourceData"
              :pagination="false"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              row-key="id"
            >
              <template #operation="{ text, record, index }">
                {{ record.name ? record.name : (record.realName ? record.realName : "") }}
              </template>
            </a-table>
          </div>
        </a-form>
      </div>
    </a-modal>
    <popupTrainees
      ref="popupTrainees"
      @sourceData="popupTraineesSourceData"
      :FormData="sourceData"
    ></popupTrainees>
  </div>
</template>
<script>
import { defineComponent } from "vue";
const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: '部门',
    dataIndex: 'departmentName',
    key: 'departmentName',
  },
  {
    title: "姓名",
    dataIndex: "name",
    slots: { customRender: "operation" },
  },
];
const from = {
  title: '',
  comment: '',
  duration: '00:01:00',
  passMark: '',
  time: [],
  examinationPaperId: ''
}
import popupTrainees from "../../components/popupTrainees"
import { ExaminationPlanController_post, ExaminationPaperController_find, ExaminationPlanController_id, ExaminationPlanController_put } from '@/api/api'
export default defineComponent({
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
    FormData: {
      type: String,
      required: false,
    },
  },
  provide() {
    return {
      addTable: this.addTable,
      delTable: this.delTable
    }
  },
  data() {
    return {
      totalPoints: 0,
      selectedRowKeys: [],
      options: [],
      sourceData: [
      ],
      editor: false,
      columns,
      visible: false,
      confirmLoading: false,
      formState: {
        ...from
      },
      rules: {
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
        comment: [{ required: true, message: '不能为空', trigger: 'blur' }],
        duration: [{ required: true, message: '不能为空', trigger: 'change' }],
        passMark: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
        time: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value.length > 0) {
              return value.toString()
            }
            return value
          }
        }],
        examinationPaperId: [{ required: true, message: '不能为空', trigger: 'change' }],
      }
    };
  },
  components: {
    popupTrainees
  },
  computed: {
    checkIds() {
      return this.sourceData.map(item => item.key)
    }
  },
  created() { },
  methods: {
    popupTraineesSourceData(val) {
      this.sourceData = val
    },
    filterOption(input, option) {
      return option.label.indexOf(input) >= 0
    },
    add() {
      this.$refs.popupTrainees.showModal('参加人员')
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    del() {
      this.sourceData = this.sourceData.filter(item => !this.selectedRowKeys.includes(item.id))
      this.selectedRowKeys = []
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...from }
      this.visible = false;
      this.sourceData = []
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        const params = {
          ...this.formState,
          examinationPlanToUsers: this.sourceData,
          startTime: this.formState.time[0],
          endTime: this.formState.time[1]
        }
        if (!this.editor) {
          ExaminationPlanController_post(params).then(res => {
            this.cancel()
            this.confirmLoading = false;
            this.$parent.refresh()
          })
        } else {
          ExaminationPlanController_put(params).then(res => {
            this.cancel()
            this.confirmLoading = false;
            this.$parent.refresh()
          })
        }
      });
    },
    showModal(val) {
      this.visible = true;
      this.editor = false
      this.options = []
      this.formState.time = []
      ExaminationPaperController_find().then(res => {
        res.data.map(res => {
          this.options.push({
            value: res.examinationPaperId,
            label: res.title + '——' + '总分：' + res.totalPoints + '分',
          })
        })
      })
      if (val) {
        this.editor = true
        ExaminationPlanController_id(val).then(res => {
          this.formState = { ...this.formState, ...res.data }
          this.formState.time.push(this.formState.startTime.substring(0, 10))
          this.formState.time.push(this.formState.endTime.substring(0, 10))
          res.data.examinationPlanToUsers.map(res => {
            this.sourceData.push({
              id: res.userId, departmentName: res.department, name: res.name
            })
          })
        })
      }
    },
    addTable(arr) {
      this.sourceData.push(...arr)
    },
    delTable(ids) {
      console.log(ids);
      this.sourceData = this.sourceData.filter(item => !ids.includes(item.id))
    }
  },
});
</script>
<style lang="less">
</style>
    