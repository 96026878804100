
import { defineComponent, PropType, reactive, toRefs, ref, h, onMounted, getCurrentInstance, ComponentInternalInstance, createVNode, } from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import { ExaminationPlanControllerPage, ExaminationPlanControllerUpdateStatus_put, ExaminationPlanController_del } from '@/api/api'
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { ExaminationPlanController,ExaminationPlanControllerDownloadSelectData } from '@/api/downloadApi'
import {blob} from '@/utils/blob'
import { message } from 'ant-design-vue';

const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "考试名称 ",
    dataIndex: "title",
    width: 200,
    ellipsis: true,
  },
  {
    title: "考卷名称",
    dataIndex: "name",
  },

  {
    title: "考试时长",
    dataIndex: "duration",
  },
  {
    title: "合格分数",
    dataIndex: "passMark",
  },
  {
    title: "生效时间",
    dataIndex: "startTime",
  },
  {
    title: "失效时间",
    dataIndex: "endTime",
  },
  {
    title: "状态",
    dataIndex: "status",
    customRender: (row: TransformCellTextProps) =>
      row.text === 0 ? "未发布" : "已发布",
  },
  {
    title: "创建人",
    dataIndex: "createdBy",
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    sorter: true,
  },
  {
    title: "更新人",
    dataIndex: "updatedBy",
  },
  {
    title: "更新时间",
    dataIndex: "updatedTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    formSearch,
    popupWindow,
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;
    const options = reactive([] as any[])
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 6 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        //试题描述：
        {
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "考试名称：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "title", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        {
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "考卷名称：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "name", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        // {
        //   rowProps: {},
        //   colProps: {
        //     span: 8,
        //   },
        //   formItemProps: {
        //     label: "考试时长(Min)：",
        //   },
        //   comConfig: {
        //     tag: "input",
        //     key: "testSlotstime",
        //     props: {
        //       placeholder: "请输入",
        //     },
        //   },
        // },

        // 有效时间
        {
          rowProps: {},
          colProps: {
            span: 6,
          },
          formItemProps: {
            label: "有效时间：",
          },
          comConfig: {
            tag: "input",
            key: "testSlots",
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[],) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref()

    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          ...formSearch.value.getQuery(),
          'startTime': updatedTime.value[0],
          'endTime': updatedTime.value[1],
          sort:params.sort
        }
        ExaminationPlanControllerPage({ ...params_api.value }).then(res => {
          reslove(res)
        })
      });
    };
    function handleDownload() {
      ExaminationPlanController({ ...params_api.value }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
        ExaminationPlanControllerDownloadSelectData({ ids:data.selectedRowKeys }).then(res => {
          blob(res)
        })
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑考试计划";
      popupWindow.value.showModal(row.examinationPlanId);
    };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const updatedTime = ref([]);
    const handleAdd = () => {
      Title.value = "新增考试计划";
      popupWindow.value.showModal();
    };
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh();
    };
    const reset = () => {
      updatedTime.value = []
    };
    const delClick = (text: string, row: any) => {
      const id = reactive([] as any[])
      id.push(row.questionId)
    }
    const refresh = () => {
      table.value.refresh();
      data.selectedRowKeys = []
    }
    const category = (val: any) => {
      return options.find(item => item.value === val)?.label
    }
    const handleDeleteAll = () => {
      (proxy as any).$confirm({
        title: "删除确认",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "确认删除选中试题吗？",
        okType: " ",
        onOk: () => {
          return new Promise((resolve) => {
            ExaminationPlanController_del({ id: data.selectedRowKeys.join(',') }).then(() => {
              data.selectedRowKeys = []
            })
              .finally(() => {
                refresh();
                resolve(true)
              });
          });
        },
      });
    };
    const handleUpdateStatus = (status: string) => {
      const statusName = status === '1' ? '发布' : '取消发布';
      (proxy as any).$confirm({
        title: `${statusName}确认`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: `确认${statusName}选中试题吗？`,
        onOk: () => {
          return new Promise((resolve) => {
            ExaminationPlanControllerUpdateStatus_put({
              status,
              examinationPlanIds: data.selectedRowKeys
            }).then(() => {
              (proxy as any).$message.success(`试题${statusName}成功`);
            })
              .finally(() => {
                resolve(true);
                refresh();
              })
          });
        },
      });
    }
    return {
      downloadSelectApi,
      handleDownload,
      handleDeleteAll,
      handleUpdateStatus,
      category,
      refresh,
      delClick,
      reset,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleAdd,
      handleSearch,
      updatedTime,
    };
  },
});
